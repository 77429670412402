/*!
 * JavaScript for analytics functions on Give as you Live websites
 * The loader checks the status of the cookie and manages the cookie banner and cookie settings window
 * It also triggers the main analytics.js file which is what actually calls Google, Zendesk etc.
 */

// Import Analytics Loader
const AnalyticsLoader = require('Scripts/common/analytics-loader');

// And run!
new AnalyticsLoader();