/**
 * Presents a GDPR consent banner on the website. Once agreed, sets a cookie to indicate the choice + hides the banner.
 * The visuals are set by /common/cookie-banner.vm (which checks for cookie so only shows if required)
 */

const Analytics      = require('Scripts/common/analytics');
const AnalyticsCheck = require('Scripts/common/analytics-check');
const Cookie         = require('Vendor/js-cookie');

const gdprCookie = "gdpr-accept-privacy-consent";
const cookieOptions = ["advertising", "analytics", "social-media"];
const cookieDefault = ["essential"];

module.exports = AnalyticsLoader;

function AnalyticsLoader() {

	this.init = function() {
		this.banner  = $("#cookie-banner");
		this.button  = $(".js-cookies-accept");
		this.modal   = $("#modal-cookies-preferences");
		this.save    = $(".js-cookies-save");
		this.decline = $(".js-cookies-decline");
		this.options = $(".js-cookies-option");
		this.binded  = false;

		var cookie = Cookie.get(gdprCookie);
		this.fix(cookie);

		if(cookie === "true") {
			console.log("AnalyticsLoader.init", "Upgrade old cookie");
			return this.acceptAll();
		}

		this.load();
	}

	this.load = function() {
		var socialBot = this.ua("facebot") || this.ua("twitterbot");
		var spider = this.ua("bot") || this.ua("spider") || this.ua("crawl") || this.ua("dotmailer");

		if(AnalyticsCheck() || socialBot) {
			console.log("AnalyticsLoader.load()", "User has given consent, load analytics per their preferences", Cookie.get(gdprCookie));
			this.banner.remove();
			new Analytics();
		} 
		else if(!spider || this.ua("googlebot")) {
			console.log("AnalyticsLoader.load()", "User hasn't given consent yet, load some analytics anyway");
			this.showConsentBanner();
			new Analytics();
		}

		if(["/cookies", "/login"].includes(window.location.pathname) && !this.binded) {
			this.bindEvents();
		}
	};

	this.ua = function(term) {
		return navigator.userAgent.toLowerCase().includes(term);
	}

	this.showConsentBanner = function() {
		console.log("AnalyticsLoader.showConsentBanner");

		if(this.isIFrame()) {
			$("#cookie-banner").remove();
			return;
		}

		this.bindEvents();
	};

	this.bindEvents = function() {
		this.button.bind("click", this.acceptAll.bind(this));
		this.options.bind("change", this.changeOption.bind(this));
		this.decline.bind("click", this.declineAll.bind(this));
		this.save.bind("click", this.saveSettings.bind(this));

		// Hide banner when modal displayed, otherwise they clash
		this.modal.on("show.bs.modal", () => this.banner.hide());
		this.modal.on("hide.bs.modal", () => this.banner.show());

		this.binded = true;
	};

	this.acceptAll = function() {
		console.log("AnalyticsLoader.acceptAll");
		this.saveSettings(true);
	};

	this.declineAll = function() {
		console.log("AnalyticsLoader.declineAll");
		this.saveSettings(false);
	};

	this.saveSettings = function(override) {
		console.log("AnalyticsLoader.saveSettings");

		// Copy defaults
		var cookieSettings = [...cookieDefault];

		// Set values
		cookieOptions.forEach((cookieOption, i) => {
			if(typeof override === 'boolean' ? override : $("#cookie-"+cookieOption).is(":checked")) {
				cookieSettings.push(cookieOption);
			}
		});

		console.log("New cookie settings", cookieSettings);
		this.setCookie(cookieSettings);

		if($('.js-cookies-refresh').length) {
			return location.reload();
		}

		this.load();
	}

	this.setCookie = function(valueArray) {
		var host = window.location.hostname;

		// "donate.giveasyoulive.com" becomes ".giveasyoulive.com"
		var cookieDomain = host.substring(host.indexOf('.'));
		var cookieValue = valueArray.join("|");

		// Ngrok doesn't work when you use wildcard domain (.ngrok.io), so we set the cookie on the subdomain instead.
		// It doesn't really matter because this value is fixed (we pay for a fixed ngrok subdomain)
		var isNgrok = host.indexOf('ngrok.io') !== -1 || host.indexOf('ngrok.app') !== -1 || host.indexOf('ngrok-free.app') !== -1;
		if(isNgrok) {
			cookieDomain = host;
		}

		// The domain needs to be set because the JS-Cookie library is a bit inconsistent with subdomains
		// This previously caused an issue where set() created a second cookie when updating its value, 
		// leaving the original untouched. Calling remove also ensures we end up with one
		Cookie.remove(gdprCookie);
		Cookie.set(gdprCookie, cookieValue, {
			expires: 3650, 
			domain: cookieDomain
		});
	}

	// A previous version of this file accidentally had a loop that was triggered by duplicate cookies, this fixes it
	this.fix = function(cookie) {
		if(cookie && cookie.length > 100) {
			var unique = cookie.split("|").filter((value, index, self) => {
  				return self.indexOf(value) === index && value != ""
			});
			
			this.setCookie(unique);
		}
	}

	this.changeOption = function() {
		console.log("AnalyticsLoader.changeOption");
		$(".js-cookies-accept-container").hide();
		$(".js-cookies-save-container").show();
	}

	this.isIFrame = function() {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}

	this.init();
}
